import { PREMIER_DATE } from '@guess-the-rose/core'
import { useInterval } from '@guess-the-rose/web-shared'
/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import countdownTimer from 'countdown'
import React, { useState } from 'react'
import { Box, Text } from 'theme-ui'

const Number = ({ duration, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: ['80px', null, null, '170px'],
      }}
    >
      <Text
        sx={{
          fontSize: '32px',
          color: 'white',
          lineHeight: 1,
          marginBottom: '10px',
        }}
      >
        {duration}
      </Text>
      <Text
        sx={{
          fontSize: '16px',
          color: 'white',
          lineHeight: 1,
        }}
      >
        {label}
      </Text>
    </Box>
  )
}

export const Countdown = () => {
  const [countdown, setCountdown] = useState({})

  useInterval(
    () => {
      setCountdown(
        countdownTimer(
          new Date(),
          PREMIER_DATE,
          countdownTimer.DAYS | countdownTimer.HOURS | countdownTimer.MINUTES,
        ),
      )
    },
    30000,
    true,
  )

  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: '26px 36px',
        background: 'linear-gradient(225deg, #FF044B 0%, #C01CA1 100%)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto' }} variant="compact">
        <Box
          sx={{
            display: [null, null, null, 'flex'],
            alignItems: [null, null, null, 'center'],
            justifyContent: [null, null, null, 'space-between'],
          }}
        >
          <Text
            sx={{
              color: 'white',
              textAlign: ['center', null, null, 'left'],
              fontSize: '18px',
              textTransform: 'uppercase',
              lineHeight: 1.2,
              marginBottom: ['22px', null, null, 0],
              maxWidth: [null, null, null, '300px'],
            }}
          >
            The bachelorette brackets for season 16 open in:
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: ['400px', null, null, 'inherit'],
              margin: ['0 auto', null, null, 0],
            }}
          >
            <Number duration={countdown.days} label="Days" />
            <Number duration={countdown.hours} label="Hours" />
            <Number duration={countdown.minutes} label="Minutes" />
          </Box>
        </Box>
      </Main>
    </Box>
  )
}
