import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@guess-the-rose/web-shared'
/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Text } from 'theme-ui'

import { withChakra } from './withChakra'

const QUESTIONS = [
  {
    question: 'Is this free?',
    answer: 'Guess the Rose is free to play!',
  },
  {
    question: 'How many leagues can I join?',
    answer:
      "There's no limit to how many leagues you can join. However, you can only have one entry per league.",
  },
  {
    question: 'What does the winner win?',
    answer:
      'Public leagues are competing for a sense of pride and accomplishment. However, feel free to up the stakes with your friends!',
  },
  {
    question: 'When will scores be updated?',
    answer: 'We will update scores the day after each show.',
  },
  {
    question: "What happens if Clare doesn't remain the bachelorette?!",
    answer:
      "If that happens, we'll reset your One True Love prediction, but you'll keep any points from weekly gameplay.",
  },
]

export const FAQContent = withChakra(() => {
  return (
    <Accordion allowMultiple>
      {QUESTIONS.map((q) => {
        return (
          <AccordionItem
            key={q.question}
            style={{
              marginBottom: '8px',
            }}
          >
            <AccordionHeader
              style={{
                appearance: 'none',
                backgroundColor: 'white',
                border: '1px solid #F0F0F0',
                boxShadow: 'none',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px 20px',
                cursor: 'pointer',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Lato,sans-serif',
                  color: '#393939',
                  fontSize: '15px',
                  textAlign: 'left',
                }}
              >
                {q.question}
              </Text>
              <AccordionIcon style={{ width: '1.5rem', height: '1.5rem' }} />
            </AccordionHeader>
            <AccordionPanel
              pb={4}
              style={{
                backgroundColor: 'white',
                border: '1px solid #F0F0F0',
                borderRadius: '0 0 5px 5px',
                borderTop: 'none',
                padding: '15px 20px',
                textAlign: 'left',
              }}
            >
              <Text
                style={{
                  fontWeight: 'medium',
                  fontFamily: 'Lato,sans-serif',
                  color: '#393939',
                  fontSize: '14px',
                  textAlign: 'left',
                }}
              >
                {q.answer}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
})

export const FAQs = () => {
  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: ['48px 36px', null, '88px 36px'],
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto', padding: 0 }} variant="compact">
        <Box sx={{}}>
          <Heading
            sx={{
              textAlign: 'left',
              textTransform: 'uppercase',
              fontWeight: 400,
              marginBottom: '36px',
            }}
          >
            FAQs
          </Heading>
          <FAQContent />
        </Box>
      </Main>
    </Box>
  )
}
